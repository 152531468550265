import clsx from 'clsx';
import { Wrapper } from 'components/Wrapper/Wrapper';

import { TopPageAdditionalImageCardProps } from 'lib/themes/themes';

import styles from './TopPageAdditionalImage.module.css';

const TopPageAdditionalImageCard = ({ additionalImage }: TopPageAdditionalImageCardProps) => {
  return (
    <Wrapper
      wrapper={(children) =>
        additionalImage?.link_url ? <a href={additionalImage?.link_url}>{children}</a> : children
      }
    >
      <div className={styles['c-card']}>
        <div className={styles['c-card__pic']}>
          {additionalImage?.image_url && <img src={additionalImage?.image_url} />}
        </div>
        <h3 className={clsx(styles['c-card__ttl'], 'maincolor-txt')}>{additionalImage?.title}</h3>
        <p className={styles['c-card__description']}>{additionalImage?.description}</p>
      </div>
    </Wrapper>
  );
};

export default TopPageAdditionalImageCard;
